<template>
  <div>
    <EarningHeadr></EarningHeadr>
    <div class="addBankCont">
      <div class="addBankTitleBox">
        <p class="f16 f0404 f_b">
          {{ addForm.id ? "编辑银行卡" : "添加银行卡" }}
        </p>
        <div class="df_Yc">
          <router-link
            v-show="stepNum === 1"
            :to="{
              path: '/personalCenter/MyEarnings',
              query: { activeName: 3 },
            }"
            class="f12 f0404 f_b c_p"
            >返回我的银行卡
          </router-link>
          <div
            v-show="stepNum === 2"
            @click="stepNum -= 1"
            class="f12 f0404 f_b c_p"
          >
            返回上一步
          </div>
          <i class="iconfont">&#xe665;</i>
        </div>
      </div>
      <div class="addBankForm" v-show="stepNum === 1">
        <el-form
          label-width="1.2rem"
          :model="addForm"
          :rules="rules"
          ref="addForm"
        >
          <el-form-item prop="username" label="开户姓名">
            <el-input
              class="w300"
              v-model="addForm.username"
              placeholder="请输入开户姓名(或企业名称)"
            ></el-input>
          </el-form-item>
          <el-form-item prop="idCard" label="身份证号">
            <el-input
              class="w300"
              v-model="addForm.idCard"
              placeholder="请填写身份证号"
            ></el-input>
          </el-form-item>
          <el-form-item prop="bankType" label="开户银行">
            <el-select
              v-model="addForm.bankType"
              placeholder="请选择开户银行"
              class="w300"
            >
              <el-option
                v-for="item in bankList"
                :key="item.bank"
                :label="item.bank"
                :value="item.bank"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="bankNumber" label="银行卡号">
            <el-input
              class="w300"
              v-model="addForm.bankNumber"
              placeholder="请填写您的银行卡号"
            ></el-input>
          </el-form-item>
          <el-form-item prop="phoneNumber" label="预留手机号">
            <el-input
              class="w300"
              v-model="addForm.phoneNumber"
              placeholder="请填写您的预留手机号"
            ></el-input>
          </el-form-item>
          <div class="nextStep" @click="submitForm('addForm')">下一步</div>
        </el-form>
      </div>
      <div class="addBankForm" v-show="stepNum === 2">
        <el-form
          label-width="1.2rem"
          :model="addFormCode"
          :rules="rules2"
          ref="addFormCode"
        >
          <el-form-item label="手机号" prop="verificationPhone">
            <el-input
              v-model="addFormCode.verificationPhone"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="code">
            <div class="df_Yc w300">
              <el-input
                style="width: 1.6rem"
                v-model="addFormCode.code"
              ></el-input>
              <div class="getCode" @click="getCode">{{ getTxt }}</div>
            </div>
          </el-form-item>
        </el-form>
        <div class="nextStep" @click="submitCodeForm('addFormCode')">
          下一步
        </div>
      </div>
      <div class="addSucceed" v-show="stepNum === 3">
        <img src="@/assets/image/icon/addBankSucceed.png" alt="" />
        <div class="dfc f24 f_b">
          <p class="f0404 mr8">{{ addForm.id ? "编辑成功" : "添加成功" }}</p>
          <router-link
            class="c_p c_f46"
            :to="{
              path: '/personalCenter/myEarnings',
              query: { activeName: 3 },
            }"
            >返回>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EarningHeadr from "./componts/EarningHeadr.vue";
import { sendMsg, addBankcard, getBankList } from "../../Api";
import { getToken } from "@/utils/auth";

export default {
  name: "MyEarningsAddBank",
  data() {
    return {
      count: "",
      timer: null,
      getTxt: "获取验证码",
      isGet: true,
      //添加步骤
      stepNum: 1,
      //添加银行卡表单
      addForm: {
        id: "",
        username: "",
        idCard: "",
        bankType: "",
        bankNumber: "",
        phoneNumber: "",
      },
      rules: {
        username: [
          { required: true, message: "请输入开户姓名(或企业名称)", trigger: "blur" },
        ],
        idCard: [
          { required: true, message: "请输入身份证号", trigger: "blur" },
          {
            pattern: /(^\d{15}$)|(^\d{17}([0-9]|X)$)/,
            message: "身份证格式不正确",
          },
        ],
        bankType: [
          { required: true, message: "请选择开户行", trigger: "blur" },
        ],
        bankNumber: [
          { required: true, message: "请输入银行卡号", trigger: "blur" },
          { pattern: /\d{16}|\d{19}/, message: "银行卡格式不正确" },
        ],
        phoneNumber: [
          { required: true, message: "请输入预留手机号", trigger: "blur" },
          {
            pattern: /^1[345789]\d{9}$/,
            message: "目前只支持中国大陆的手机号码",
          },
        ],
      },
      addFormCode: {
        verificationPhone: "",
        code: "",
      },
      rules2: {
        verificationPhone: [
          { required: true, message: "请输入验证手机号", trigger: "blur" },
          {
            pattern: /^1[345789]\d{9}$/,
            message: "目前只支持中国大陆的手机号码",
          },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      bankList: [
        { bank: "中国银行" },
        { bank: "中国工商银行" },
        { bank: "中国建设银行" },
        { bank: "中国农业银行" },
        { bank: "中国邮政储蓄银行" },
        { bank: "华夏银行" },
        { bank: "招商银行" },
        { bank: "交通银行" },
        { bank: "中信银行" },
        { bank: "兴业银行" },
        { bank: "中国民生银行" },
        { bank: "中国光大银行" },
        { bank: "广发银行" },
        { bank: "深圳发展银行" },
        { bank: "渤海银行" },
        { bank: "浙商银行" },
        { bank: "恒丰银行" },
        { bank: "浦发银行" },
      ],
    };
  },
  components: {
    EarningHeadr,
  },
  created() {
    //判断是否是编辑
    if (this.$route.query.bank_id) {
      this.addForm.id = this.$route.query.bank_id;
      //获取银行卡列表
      getBankList(getToken()).then((res) => {
        let data = res.data.data
        data.forEach((item) => {
          if(item.id == this.addForm.id){
            this.addForm.username = item.trueName
            this.addForm.idCard = item.userCode
            this.addForm.bankType = item.type
            this.addForm.bankNumber = item.cardCode
            this.addForm.phoneNumber = item.phone
          }
        })
      });
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.stepNum += 1;
        } else {
          return false;
        }
      });
    },
    //第二步
    submitCodeForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const data = {
            id:this.addForm.id,
            trueName: this.addForm.username,
            userCode: this.addForm.idCard,
            bankName: this.addForm.bankType,
            cardCode: this.addForm.bankNumber,
            phone: this.addForm.phoneNumber,
          };
          addBankcard(data, getToken()).then((res) => {
            if (res.status === "200" && res.data.message === "addSuccess") {
              this.stepNum += 1;
            }
          });
        } else {
          return false;
        }
      });
    },
    // 发送验证码
    getCode() {
      if (this.isGet) {
        sendMsg({ tel: this.addFormCode.verificationPhone }).then((res) => {
          if (res.error === "0") {
            this.$message.success("发送成功");
            this.isGet = false;
            const TIME_COUNT = 60;
            if (!this.timer) {
              this.count = TIME_COUNT;
              this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= TIME_COUNT) {
                  this.getTxt = "重新获取" + this.count;
                  this.count--;
                } else {
                  this.getTxt = "重新获取";
                  this.isGet = true;
                  clearInterval(this.timer);
                  this.timer = null;
                }
              }, 1000);
            }
          } else {
            this.$message.error("发送失败");
          }
        });
      }
    },
  },
  watch: {
    "addForm.phoneNumber"(value) {
      this.addFormCode.verificationPhone = value;
    },
  },
};
</script>

<style scoped>
.addSucceed {
  text-align: center;
  margin-top: 1.5rem;
}

.addSucceed img {
  width: 2.26rem;
  height: 2.24rem;
}

.getCode {
  padding: 0 0.15rem;
  line-height: 0.38rem;
  height: 0.38rem;
  background: #ffffff;
  border: 0.01rem solid #040404;
  border-radius: 0.04rem;
  color: #040404;
  font-size: 0.14rem;
  margin-left: 0.2rem;
  cursor: pointer;
}

::v-deep .el-form-item__label {
  text-align: left;
}

.nextStep {
  cursor: pointer;
  width: 1.55rem;
  height: 0.4rem;
  line-height: 0.4rem;
  text-align: center;
  color: #ffffff;
  font-size: 0.14rem;
  font-weight: bold;
  background: #f46600;
  border-radius: 0.04rem;
  margin-left: auto;
  margin-top: 0.6rem;
}

.addBankForm {
  width: 4.2rem;

  margin-top: 0.4rem;
}

::v-deep .el-form-item__label {
  font-size: 0.14rem;
  font-weight: bold;
}

::v-deep .el-input {
  font-size: 0.14rem;
}

::v-deep .el-input__inner {
  height: 0.38rem;
  line-height: 0.38rem;
}

.addBankCont {
  margin-top: 0.2rem;
  padding: 0.3rem;
  background: #ffffff;
  border-radius: 0.04rem;
  height: 7.4rem;
}

.addBankTitleBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.1rem;
  border-bottom: 0.03rem solid #eeeeee;
}
</style>