<!--
 * @FilePath: \zhishe-pc\src\views\personalCenter\componts\EarningHeadr.vue
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2021-11-23 14:04:10
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2022-01-07 14:41:13
-->
<template>
  <div class="eamarningHeadBox">
    <div class="eamarningHeadLeft">
      <img src="@/assets/image/icon/myEarnings-icon.png" alt="" />
      <p class="f14 c_99 mt10">账户余额</p>
      <p class="f24 f0404 mt10">
        {{ userMoney }}<span class="f14 c_99">元</span>
      </p>
      <div class="tixianB" @click="$emit('showPop')">提现</div>
    </div>
    <div class="eamarningHeadRight">
      <p class="c_66 f16">收益情况</p>
      <div class="eamarningInfo">
        <div>
          <p class="f_b f0404 f24 t_c">{{ userPayIncome.all }}</p>
          <p class="t_c f14 c_99 mt10">累计收益</p>
        </div>
        <div class="lineY"></div>
        <div>
          <p class="f_b f0404 f24 t_c">{{ userPayIncome.yesterday }}</p>
          <p class="t_c f14 c_99 mt10">昨日收益</p>
        </div>
        <div class="lineY"></div>
        <div>
          <p class="f_b f0404 f24 t_c">{{ userPayIncome.week }}</p>
          <p class="t_c f14 c_99 mt10">本周收益</p>
        </div>
        <div class="lineY"></div>
        <div>
          <p class="f_b f0404 f24 t_c">{{ userPayIncome.month }}</p>
          <p class="t_c f14 c_99 mt10">本月收益</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getToken } from "../../../utils/auth";
import { getMyIncomeStatistic,getEarningsInfo } from "../../../Api";

export default {
  name: "EarningHeadr",
  data() {
    return {
      userPayIncome: {},
      userMoney:0,
      userEarningsInfo:{}
    };
  },
  created() {
    this.getMyIncomeStatistic()
    //获取用户收益
    getEarningsInfo(getToken()).then((res) => {
      this.userEarningsInfo = res.data;
    });
  },
  methods: {
    
    getMyIncomeStatistic() {
      getMyIncomeStatistic({ token: getToken() }).then((res) => {
        this.userPayIncome = res.data.userPayIncome;
        this.userMoney = res.data.myBalance
      });
    },
  },
};
</script>

<style scoped>
.eamarningInfo {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  justify-content: space-between;
  padding: 0 0.3rem;
}
.eamarningHeadBox {
  display: flex;
}
.lineY {
  width: 0.02rem;
  height: 00.4rem;
  background: #d9d9d9;
}
.eamarningHeadRight {
  flex: 1;
  border-radius: 0.04rem;
  background: #ffffff;
  padding: 0.2rem 0.3rem;
}
.tixianB {
  width: 1rem;
  height: 0.32rem;
  background: #f46600;
  border-radius: 0.04rem;
  text-align: center;
  line-height: 0.32rem;
  margin: 0.3rem auto 0;
  color: #ffffff;
  font-size: 00.14rem;
  cursor: pointer;
}
.eamarningHeadLeft img {
  width: 0.32rem;
  height: 0.32rem;
}
.eamarningHeadLeft {
  background: #ffffff;
  border-radius: 0.04rem;
  text-align: center;
  padding: 0.3rem 0;
  width: 2rem;
  margin-right: 00.1rem;
}
.eamarningHeadBox {
  display: flex;
}
</style>